import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ThemeContext from "../../context/ThemeContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquare,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const FaqPage = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Techimpace Best Digital Agency for Website Designing, Web Development and Software Development" />
          <section className={theme.dark ? " dark dark-5 " : " light "}>
            <div className="container">
              <div className="row">
                <div className="row">
                  <div className="col-12">
                    <h1>FAQ - Frequently Asked Questions</h1>
                    <p>
                      On this page, we have collected the most common questions
                      about our services and software/app development and the
                      answers to them. If you can’t find what you’re looking for
                      then feel free to ask us directly via
                      <Link to="/contact"> contacting us. </Link>
                    </p>
                    <hr />
                  </div>
                  <div className="col-12">
                    <h4>Q: What is Techimpace?</h4>
                    <p>
                      Techimpace is a full-service software development company
                      that helps businesses of all sizes create custom software
                      solutions. We have a team of experienced and talented
                      developers who can build anything from simple websites to
                      complex enterprise applications.
                    </p>
                    <h4>Q: What are the main industries we serve?</h4>
                    <p>
                      To provide the best services in the market we mainly focus
                      our software development outsourcing services on product
                      development for several primary industries: E-commerce
                      Development Solutions Healthcare Application Development
                      Software Development For Real Estate Besides, we have a
                      lot of successful projects in other industries like
                      entertainment, financial, hospitality, media, education
                      and other.
                    </p>
                    <h4>
                      Q: What technologies do we use in software development?
                    </h4>
                    <p>
                      We have experience with a wide range of technologies,
                      including:
                    </p>
                    <ul>
                      <li>
                        <b>Programming languages:</b> Java, Python, JavaScript,
                        C++, C#, etc.
                      </li>
                      <li>
                        <b>Databases:</b> MySQL, PostgreSQL, Oracle, SQL Server,
                        etc.
                      </li>
                      <li>
                        <b>Cloud computing platforms:</b> AWS, Azure, GCP, etc.
                      </li>
                      <li>
                        <b>Web development frameworks:</b> Codeigniter, Spring
                        Boot, Django, Laravel, etc.
                      </li>
                      <li>
                        <b>Mobile development frameworks:</b> Flutter, React
                        Native, NativeScript, etc.
                      </li>
                    </ul>
                    <p>
                      Thus, if you’d like to implement some recent technologies
                      into your project and make it look updated, outsourcing to
                      our specialists experienced in that niche will be the best
                      option.
                    </p>
                    <h4>
                      Q: How much does it cost to develop software with
                      Techimpace Software Agency?
                    </h4>
                    <p>
                      The cost of developing software with us varies depending
                      on the complexity of the project and the technologies
                      used. However, we offer competitive rates and we are
                      committed to working with our clients to find a solution
                      that fits their budget.
                    </p>
                    <h4>
                      Q: How long does it take to develop software with
                      Techimpace Software Agency?
                    </h4>
                    <p>
                      The development timeline also varies depending on the
                      complexity of the project. However, we are committed to
                      delivering projects on time and within budget.
                    </p>
                    <h4>
                      Q: How does Techimpace Software Agency ensure the quality
                      of its software?
                    </h4>
                    <p>
                      We have a rigorous quality assurance process in place to
                      ensure that our software is of the highest quality. We
                      test our software throughout the development process and
                      we fix any bugs that are found. We also offer a warranty
                      on our software, so our clients can be confident that they
                      are getting a high-quality product.
                    </p>
                    <h4>
                      Q: How do I get started with Techimpace Software Agency?
                    </h4>
                    <p>
                      To get started, simply
                      <Link to="/contact"> Contact Us </Link>
                      and schedule a free consultation. We will discuss your
                      needs and requirements in detail and we will develop a
                      custom proposal for you. Once we have agreed on the terms
                      of the project, we will start working on your software
                      solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default FaqPage
